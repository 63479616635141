<template>
  <div>
    <b-modal
      id="modal_formulario_convocatoria"
      ok-only
      size="lg"
      class="p-5"
      title="Formulario"
      no-close-on-esc
      no-close-on-backdrop
      modal-class="modal-success"
      :hide-footer="true"
      @close="updatePreinscripcion"
    >
      <formulario
        :id-formulario="idFormulario"
        :id-usuario="idUsuario"
      />
      <template #modal-footer>
        <div class="w-100" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import formulario from '@/views/administrador/gestion-formulario/formulario/Formulario.vue'

export default {
  components: {
    formulario,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    idFormulario: {
      type: Number,
      default: 0,
    },
    idUsuario: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // idUsuario: 500,
      // idFormulario: 2,
    }
  },
  created() {
  },
  methods: {
    updatePreinscripcion() {
      this.$emit('updatePreinscripcion')
    },
  },

}
</script>

<style lang="scss">
</style>
