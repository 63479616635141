<template>
  <div>

    <b-card
      class="text-center m-0"
      :class="botonActive ? 'bg-light-primary' : 'bg-light-success'"
    >
      <div class="">
        <h4 class="text-primary">
          <strong>
            <feather-icon
              icon="HomeIcon"
              size="15"
            />
            PROCESO DE POSTULACIÓN :  {{ dataConvocatoria.nombre }}
          </strong>
        </h4>
        <div>
          <span class="text-danger mr-1"><strong>Fecha inicio:</strong> </span>
          <span v-if="dataConvocatoria.fecha_inicio"> {{ new Intl.DateTimeFormat('es-PE',{month: 'long', day:'numeric', year:'numeric', hour:'numeric', minute: 'numeric'}).format(new Date(`${dataConvocatoria.fecha_inicio}`)) }}</span>
          <br>
          <span class="text-danger mr-1"><strong>Fecha fin:</strong> </span>
          <span v-if="dataConvocatoria.fecha_fin"> {{ new Intl.DateTimeFormat('es-PE',{month: 'long', day:'numeric', year:'numeric', hour:'numeric', minute: 'numeric'}).format(new Date(`${dataConvocatoria.fecha_fin}`)) }}</span>
        </div>

      </div>
    </b-card>
    <b-card class="my-1">
      <b-row class="my-1">
        <b-col
          cols="7"
          style="margin:auto"
          class="text-center"
        >
          <b-form-group
            v-slot="{ ariaDescribedby }"
            label="Seleccione los servicios a postular:"
          >
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="selectedServicios"
              :options="optionsServicios"
              :aria-describedby="ariaDescribedby"
              name="flavour-1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="8"
          style="margin:auto"
        >
          <b-table-simple
            class="border"
          >
            <b-tr>
              <b-th class=" py-2">
                REQUISITOS

              </b-th>
              <b-th class="text-center">
                ESTADO
              </b-th>
            </b-tr>
            <b-tr
              v-for="(item, index) in requisitos"
              :key="index"
            >
              <b-td class="">
                <a
                  class="hoverRequisito"
                  @click="abrirModal(item.requisito.formulario_maestro_id, item.id)"
                >
                  <span class="hoverRequisito">
                    <feather-icon
                      icon="PlayIcon"
                      size="15"
                    />
                    {{ item.requisito.nombre }}
                  </span>

                </a>

              </b-td>
              <b-td class="text-center">
                <small
                  class="mr-1"
                  :class="item.estado == 'pendiente' ? 'text-warning' : 'text-success'"
                >{{ item.estado }}</small>
                <feather-icon
                  :icon="item.estado == 'pendiente' ? 'XCircleIcon' : 'CheckCircleIcon'"
                  size="20"
                  :class="item.estado == 'pendiente' ? 'text-warning' : 'text-success'"
                /></b-td>
            </b-tr>
            <b-overlay
              :show="showOverlay"
              no-wrap
              variant="ligth"
            />
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <div class="text-center">
        <b-button
          :variant="botonActive ? 'outline-danger' : 'success'"
          :disabled="botonActive"
          @click="registrarPostulacion"
        >
          Registrar postulación
          <feather-icon
            icon="CheckIcon"
            size="16"
          />
        </b-button>
      </div>
    </b-card>

    <ModalFormularioConvocatoria
      :id-formulario="idFormulario"
      :id-usuario="idUsuario"
      @updatePreinscripcion="updatePreinscripcion"
    />
    <br>

  </div>
</template>

<script>
import {
  BCard, BTableSimple, BTr, BTd, BButton, BTh, BRow, BCol, BFormGroup, BFormCheckboxGroup, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ModalFormularioConvocatoria from './ModalFormularioConvocatoria.vue'
import postulacionStoreModule from './postulacionStoreModule'

export default {
  components: {
    BCard,
    FeatherIcon,
    BOverlay,
    // BRow,
    BTableSimple,
    BTr,
    BTd,
    BButton,
    BTh,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckboxGroup,

    // extern
    ModalFormularioConvocatoria,
    // formulario,
  },
  data() {
    return {
      idUsuario: 0,
      requisitos: [],
      servicios: [],
      dataConvocatoria: {},
      selectedServicios: [], // Must be an array reference!
      optionsServicios: [
      ],
      idFormulario: 0,
      idRequisito: 0,

      showOverlay: false,
    }
  },

  computed: {
    botonActive() {
      return this.requisitos.some(value => value.estado === 'pendiente')
    },
  },
  created() {
    // asignamos usuario id
    if (localStorage.getItem('userData').id !== null) {
      this.idUsuario = JSON.parse(localStorage.getItem('userData')).id
    }

    // si el estado de la convocatoria es diferente de VIGENTE enviar a la lista de convocatorias
    store.dispatch('postulacion-module/showConvocatoria', this.$router.currentRoute.params.id).then(res => {
      this.dataConvocatoria = res.data
    })
  },
  mounted() {
    // PETICION SERVICIOS
    store.dispatch('postulacion-module/getServicios', this.$router.currentRoute.params.id)
    // this.$http.get(`/servicio-social/beca/convocatoria/${this.$router.currentRoute.params.id}/servicios`)
      .then(res => {
        this.optionsServicios = res.data.map(value => ({ text: value.nombre, value: value.id }))
      })

    // PETICION PRE-INSCRIPCION
    store.dispatch('postulacion-module/getPreinscripcion', this.$router.currentRoute.params.id)
      .then(res => {
        this.requisitos = res.data
      })
  },
  methods: {
    abrirModal(idFormulariox, requisitoIdx) {
      this.idFormulario = idFormulariox
      this.idRequisito = requisitoIdx
      this.$bvModal.show('modal_formulario_convocatoria')
    },
    updatePreinscripcion() {
      this.showOverlay = true
      store.dispatch('postulacion-module/updatePreinscripcion', { idConvocatoria: this.$router.currentRoute.params.id, idRequisito: this.idRequisito })
        .then(res => {
          if (res.data.estado === undefined) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '¡Información!',
                text: res.data.data,
                icon: 'FrownIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '¡Formulario completado!',
                icon: 'SmileIcon',
                variant: 'success',
              },
            })
          }

          this.requisitos.forEach((value, index) => {
            if (value.requisito_id === res.data.requisito_id) {
              this.requisitos[index].estado = res.data.estado
            }
          })
          this.showOverlay = false
        })
    },
    registrarPostulacion() {
      if (!this.selectedServicios.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Advertencia!',
            text: 'Seleccione por lo menos 1 servicio',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
        return
      }
      store.dispatch('postulacion-module/registrarPostulacion', { idConvocatoria: this.$router.currentRoute.params.id, servicios: { servicios_id: this.selectedServicios } })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Éxito!',
            text: 'Su registro a la convocatoria a sido guardado correctamente. Acercate a las oficinas de Bienestar Universitario para tu Entrevista.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            this.$router.push({ name: 'social-convocatorias-list' })
          })
        })
    },
  },
  setup() {
    const POSTULACION_APP_STORE_MODULE_NAME = 'postulacion-module'

    // Register module
    if (!store.hasModule(POSTULACION_APP_STORE_MODULE_NAME)) store.registerModule(POSTULACION_APP_STORE_MODULE_NAME, postulacionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(POSTULACION_APP_STORE_MODULE_NAME)) store.unregisterModule(POSTULACION_APP_STORE_MODULE_NAME)
    })

    return {
      postulacionStoreModule,
    }
  },

}
</script>

<style>
.hoverRequisito:hover{
  color: green;
  font-weight: bold;
  /* background-color: red; */
}
</style>
